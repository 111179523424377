<script setup>
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { mobileUtils } from "o365-mobile";
import ContextSelector from 'o365.pwa.vue.components.ContextSelector.vue';
import { OModal, OIframe } from 'o365-ui-components';
import { API } from 'o365-modules';
import { context } from 'o365-modules';

const { isMobile } = mobileUtils;

const props = defineProps({
    src: {
        type: String,
        required: true
    },
    appState: {
        type: Object,
        required: true
    },
    startSync: {
        type: Function,
        required: true
    },
    getCheckedOutRecordCount: {
        type: Function,
        required: true
    },
    clearAndCheckInCheckedOutRecords: {
        type: Function,
        required: true
    }
});

const iframe = ref(null);
const iframeWindow = ref(null);
const navigationHistory = ref([]);
const currentContext = ref(null);
const modal = ref();
const conflictingContext = ref(null);

const isIframeLoading = ref(true);
const isCheckoutButtonHovered = ref(false);
const isCheckedOutRecordsUpdating = ref(false);

const disableDownload = computed(() => {
    return isCheckedOutRecordsUpdating.value || props.appState.loadingCheckedOutRows || props.appState.checkedOutRecordCount === 0;
});

const goBackIframe = () => {
    if (navigationHistory.value.length > 1) {
        navigationHistory.value.pop();
        const navigateToUrl = navigationHistory.value[navigationHistory.value.length - 1];
        iframe.value.src = navigateToUrl.toString();
    }
}

const reloadIframe = () => {
    iframeWindow.value.location.reload();
}

const goToRegisterIframe = () => {
    if (navigationHistory.value.length > 1) {
        const navigateToUrl = navigationHistory.value[0];
        navigationHistory.value = [];
        iframe.value.src = navigateToUrl.toString();
    }
}

const onIframeAfterNavigate = () => {
    isIframeLoading.value = true;

    const iframeUrl = new URL(iframeWindow.value.location.href);
    const iframeParams = new URLSearchParams(iframeUrl.search);

    // Make sure the iframe has hidenav
    if (!iframeParams.has('HideNav')) {
        iframeParams.set('HideNav', 'true');
        iframeUrl.search = iframeParams.toString();

        iframe.value.src = iframeUrl.toString();
        return;
    }

    isIframeLoading.value = false;

    const urlExistsInHistory = navigationHistory.value.some(historyUrl => {
        return `${iframeUrl.hostname}${iframeUrl.pathname}` === `${historyUrl.hostname}${historyUrl.pathname}`;
    });

    if (urlExistsInHistory) {
        return;
    }

    navigationHistory.value.push(iframeUrl);
}

const updateCheckedOutRecordsCount = async () => {
    isCheckedOutRecordsUpdating.value = true;
    await props.getCheckedOutRecordCount();
    isCheckedOutRecordsUpdating.value = false;
}

const syncCheckedOutItems = async () => {
    if (disableDownload.value) {
        return;
    }

    if (currentContext.value) {
        const fetchContext = await API.requestPost('/api/user/orgunitcontext/get');
        
        if (fetchContext.id !== currentContext.value.id) {
            conflictingContext.value = fetchContext;
            modal.value.show();
            return;
        }
    }

    isCheckedOutRecordsUpdating.value = true;
    await props.startSync('OFFLINE-SYNC');
    isCheckedOutRecordsUpdating.value = false;
}

const clearCheckedOutItems = async () => {
    isCheckedOutRecordsUpdating.value = true;
    await props.clearAndCheckInCheckedOutRecords();
    isCheckedOutRecordsUpdating.value = false;
}

const onContextChanged = (contextID) => {
    currentContext.value = contextID;
    iframeWindow.value?.postMessage(`{"type": "contextUpdate", "id": "${contextID}"}`);
}

const onMessage = (event) => {
    try {
        const messageObject = JSON.parse(event.data);

        switch (messageObject.type) {
            case 'checkOutUpdate':
                updateCheckedOutRecordsCount();
                break;
        }
    } catch (_reason) { }
}

onMounted(async () => {
    updateCheckedOutRecordsCount();
    
    currentContext.value = await API.requestPost('/api/user/orgunitcontext/get');

    iframeWindow.value = iframe.value.contentWindow ?? null;
    iframe.value.addEventListener('load', onIframeAfterNavigate, false);

    window.addEventListener('message', onMessage, false);
});

onBeforeUnmount(() => {
    window.removeEventListener('message', onMessage, false);
    iframe.value.removeEventListener('load', onIframeAfterNavigate, false);
});

const resolveOrgUnitConflict = async (id) => {
    conflictingContext.value = null;

    await context.setContext(id);

    modal.value?.hide();
    props.startSync('OFFLINE-SYNC');
}

const onNewContext = (context) => {
    currentContext.value = context;
}

</script>

<template>
    <ORowContainer class="gap-1 bg-white">
        <div v-if="isIframeLoading" class="overlay">
            <div class="overlay__inner">
                <div class="overlay__content"><span class="spinner"></span></div>
            </div>
        </div>

        <div class="d-flex align-items-center gap-3 py-2" :class="{ 'justify-content-around border-bottom': isMobile, 'justify-content-between mx-3': !isMobile }">
            <div class="btn-group">
                <button class="btn btn-outline-primary"
                    :class="{ 'btn-sm': isMobile }"
                    :disabled="isCheckedOutRecordsUpdating ? true : null"
                    @click="syncCheckedOutItems"
                    @mouseover="isCheckoutButtonHovered = true"
                    @mouseleave="isCheckoutButtonHovered = false">
                    <span class="badge text-bg-primary me-1" :class="{ 'bg-white text-primary': isCheckoutButtonHovered }">
                        {{ appState.loadingCheckedOutRows ? '0' : appState.checkedOutRecordCount }}
                    </span>
                    
                    {{ $t('Items checked out') }}

                    <i class="bi bi-download text-primary ms-1" :class="{ 'text-white': isCheckoutButtonHovered }"></i>

                    <div v-if="isCheckedOutRecordsUpdating" class="spinner-border spinner-border-sm ms-2" role="status">
                        <span class="visually-hidden">{{ $t('Loading...') }}</span>
                    </div>
                </button>

                <button class="btn btn-outline-primary dropdown-toggle dropdown-toggle-split"
                    :class="{ 'btn-sm': isMobile }"
                    :disabled="isCheckedOutRecordsUpdating ? true : null"
                    data-bs-toggle="dropdown"
                    aria-expanded="false">
                    <span class="visually-hidden">{{ $t('Toggle Dropdown') }}</span>
                </button>

                <ul class="dropdown-menu dropdown-menu-end">
                    <li class="dropdown-item" @click="clearCheckedOutItems">
                        <i class="bi bi-x-circle text-danger me-1"></i>

                        {{ $t('Clear checked out items') }}
                    </li>
                </ul>
            </div>

            <template v-if="!isMobile">
                <div class="d-flex justify-content-around gap-2">
                    <button class="btn btn-link btn-sm" :disabled="navigationHistory.length > 1 ? null : true"
                        @click="goBackIframe">
                        <i class="bi bi-chevron-left"></i>
                        {{ $t('Back') }}
                    </button>

                    <button class="btn btn-link btn-sm" @click="reloadIframe">
                        <i class="bi bi-arrow-clockwise me-1"></i>
                        {{ $t('Reload') }}
                    </button>

                    <button class="btn btn-link btn-sm" :disabled="navigationHistory.length > 1 ? null : true"
                        @click="goToRegisterIframe">
                        <i class="bi bi-layout-text-window me-1"></i>
                        {{ $t('Go to register') }}
                    </button>
                </div>
            </template>

            <div :class="{ 'me-2': isMobile }" :style="isMobile ? 'max-width:40vw;font-size:90%;' : null">
                <ContextSelector @contextChanged="onContextChanged" @newContext="onNewContext"></ContextSelector>
            </div>
        </div>

        <iframe ref="iframe" :src="src" class="h-100 w-100" style="margin-top:-4px!important;margin-bottom:-5px!important;"
            frameborder="0"></iframe>

        <template v-if="isMobile">
            <div class="d-flex justify-content-around border-top gap-2 py-2">
                <button class="btn btn-link btn-sm" :disabled="navigationHistory.length > 1 ? null : true"
                    @click="goBackIframe">
                    <i class="bi bi-chevron-left"></i>
                    {{ $t('Back') }}
                </button>

                <button class="btn btn-link btn-sm" @click="reloadIframe">
                    <i class="bi bi-arrow-clockwise me-1"></i>
                    {{ $t('Reload') }}
                </button>

                <button class="btn btn-link btn-sm" :disabled="navigationHistory.length > 1 ? null : true"
                    @click="goToRegisterIframe">
                    <i class="bi bi-layout-text-window me-1"></i>
                    {{ $t('Go to register') }}
                </button>
            </div>
        </template>
    </ORowContainer>
    <template v-if="isMobile">
        <MBottomSheet v-model="modalOpen" :title="$t('OrgUnit Conflict')" static>
            <div class="vstack gap-2 p-3">
                <div class="p-2">
                    There was a conflict with your selected context, please select the correct context below.
                </div>
                <div class="vstack gap-2 p-3">
                    <OButton class="w-100" color="primary" :text="currentContext?.orgUnit"
                        @click="resolveOrgUnitConflict(currentContext.id)" />
                    <OButton class="w-100" color="primary" :text="conflictingContext?.orgUnit"
                        @click="resolveOrgUnitConflict(conflictingContext.id)" />
                </div>
            </div>
        </MBottomSheet>
    </template>
    <template v-else>
        <OModal ref="modal" dataBsBackdrop="static">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ $t("OrgUnit Conflict") }}</h5>
                    </div>
                    <div class="modal-body" style="height: 20vh;">
                        <div class="position-absolute inset-0">
                            <div class="p-2">
                                There was a conflict with your selected context, please select the correct context below.
                            </div>
                            <div class="vstack gap-2 p-3">
                                <OButton class="w-100" color="primary" :text="currentContext?.orgUnit"
                                    @click="resolveOrgUnitConflict(currentContext.id)" />
                                <OButton class="w-100" color="primary" :text="conflictingContext?.orgUnit"
                                    @click="resolveOrgUnitConflict(conflictingContext.id)" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </OModal>
    </template>
</template>